import React from "react";
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import { Tooltip, Legend } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import {
  Tooltip as Tooltip2,
  BarChart as BarChart2,
  Bar as Bar2,
  XAxis as XAxis2,
  YAxis as YAxis2,
  Legend as Legend2,
} from "recharts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MarksDistributionChart = ({ questions }) => {
  // Prepare data
  const marksData = questions.map((question, index) => ({
    name: `Q${index + 1}`,
    marks: Math.min(
      10,
      question.conversationMap.reduce(
        (acc, conv) => acc + (conv.marksAwarded || 0),
        0
      )
    ), // Ensure marks are capped at 10
  }));

  // Check if all marks are 0
  const allMarksZero = marksData.every((data) => data.marks === 0);

  return (
    <Box
      overflowX="scroll"
      p={4}
      borderRadius="md"
      boxShadow="md"
      bg="white"
      mx="auto"
      px="auto"
    >
      <Heading size="md" mb="4">
        Marks Distribution (Out of 10)
      </Heading>
      {allMarksZero ? (
        <Box textAlign="center" mt={4}>
          <Text fontSize="lg" color="gray.600">
            0 marks awarded for every question. Data will be displayed when
            marks are non-zero for at least 1 question.
          </Text>
        </Box>
      ) : (
        <BarChart2 width={500} height={300} data={marksData}>
          <XAxis2 dataKey="name" />
          <YAxis2 domain={[0, 10]} /> {/* Set Y-axis range explicitly */}
          <Tooltip2 />
          <Legend2 />
          <Bar2 dataKey="marks" fill="#8884d8" />
        </BarChart2>
      )}
    </Box>
  );
};

const ImprovementAreasChart = ({ interviewDetails }) => {
  if (
    !interviewDetails?.categorywiseMarks ||
    interviewDetails.categorywiseMarks.length === 0
  ) {
    return null;
  }

  // Prepare data for the bar chart
  const chartData = {
    labels: interviewDetails.categorywiseMarks.map((marks) => marks.category),
    datasets: [
      {
        label: "Marks Percentage",
        data: interviewDetails.categorywiseMarks.map(
          (marks) => marks.marksPercentage
        ),
        backgroundColor: [
          "#FF6384", // Example colors for bars
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        borderColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Prevent chart from being squished
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Improvement Areas",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          display: true,
          text: "Marks Percentage (%)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Categories",
        },
      },
    },
  };

  return (
    <Box mb="8" overflowX="auto">
      <Heading size={{ base: "sm", lg: "md" }} mb="4" mt="8" textAlign="center">
        Improvement Areas (% Marks Categorywise)
      </Heading>
      <Box
        mx="auto"
        minWidth={{ base: "600px", md: "800px", lg: "1000px" }} // Ensure the chart width is scrollable
        height={{ base: "300px", md: "400px", lg: "500px" }} // Responsive height
        overflowX="auto"
      >
        <Bar data={chartData} options={options} />
      </Box>
    </Box>
  );
};

const InterviewDetails = ({ data }) => {
  const { interviewDetails, questions } = data;

  const isMobile = window.innerWidth < 768;

  return (
    <Box mt="6" p="4" boxShadow="lg" borderWidth="1px" borderRadius="lg">
      <Heading size="lg" mb="6">
        {interviewDetails.title}
      </Heading>
      <Text mb="4">
        <strong>Domain:</strong> {interviewDetails.domain} |{" "}
        <strong>Role:</strong> {interviewDetails.role}
      </Text>

      <MarksDistributionChart questions={questions}></MarksDistributionChart>

      <ImprovementAreasChart
        interviewDetails={interviewDetails}
      ></ImprovementAreasChart>

      {/* Feedback and Answers for Each Question */}
      <Box mb="8" bg="white" p="6" borderRadius="lg" boxShadow="md">
        <Heading size="md" mb="6" textAlign="center" color="teal.600">
          Question, Feedback, Improvement Suggestions, and Recordings
        </Heading>
        <Box overflowX="auto">
          <TableContainer>
            <Table
              variant="simple"
              colorScheme="teal"
              size={isMobile ? "sm" : "md"}
              boxShadow="sm"
            >
              <Thead bg="teal.50">
                <Tr>
                  <Th fontSize="sm" textAlign="center">
                    Question
                  </Th>
                  <Th fontSize="sm" textAlign="center">
                    User Answer
                  </Th>
                  <Th fontSize="sm" textAlign="center">
                    Correct Answer
                  </Th>
                  <Th fontSize="sm" textAlign="center">
                    Feedback
                  </Th>
                  <Th fontSize="sm" textAlign="center">
                    Marks Awarded
                  </Th>
                  <Th fontSize="sm" textAlign="center">
                    Video Recordings
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {questions.map((question, qIndex) =>
                  question.conversationMap.map((conv) => (
                    <Tr key={`${conv._id}-${qIndex}`}>
                      <Td minW="200px">
                        <Text
                          fontSize="sm"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {question.questionText}
                        </Text>
                      </Td>
                      <Td minW="200px">
                        <Text
                          fontSize="sm"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {conv.userAnswered}
                        </Text>
                      </Td>
                      <Td minW="250px">
                        <Text
                          fontSize="sm"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {question.correctAnswer ||
                            "No Correct Answer Available"}
                        </Text>
                      </Td>
                      <Td minW="250px">
                        <Text
                          fontSize="sm"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {conv.feedback || "No feedback provided"}
                        </Text>
                      </Td>
                      <Td textAlign="center" width="100px" fontWeight="bold">
                        {conv.marksAwarded || 0}
                      </Td>
                      <Td minW="300px">
                        {question.allVideoFiles?.length > 0 ? (
                          question.allVideoFiles.map((videoUrl, vIndex) => (
                            <Box key={vIndex} mb="4">
                              <video
                                style={{ width: "100%", maxWidth: "400px" }}
                                controls
                              >
                                <source src={videoUrl} type="video/webm" />
                                Your browser does not support the video tag.
                              </video>
                            </Box>
                          ))
                        ) : (
                          <Text fontSize="sm">
                            No videos available for this question.
                          </Text>
                        )}
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default InterviewDetails;
