import React from "react";
import {
  Box,
  Flex,
  Heading,
  Link,
  HStack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Box
      as="header"
      bg="teal.500"
      color="white"
      px={4}
      paddingRight={{ base: "15%", lg: "10%" }}
      position="fixed"
      top={0}
      width="100%"
      zIndex={1000}
    >
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <Box>
          <Link
            href="/"
            textDecoration={"none"}
            _hover={{ textDecoration: "none" }}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Image height={"40px"} src="/logo.png" alt="logo" mr="20px"></Image>
            <Heading size="md">TryInterview AI</Heading>
          </Link>
        </Box>
        {isHomePage && !isMobile && (
          <HStack as="nav" spacing={{ base: 2, lg: 4 }}>
            <Link
              href="#about"
              _hover={{ textDecoration: "none", color: "teal.300" }}
            >
              About
            </Link>
          </HStack>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
