import React from "react";
import {
  Box,
  Heading,
  List,
  ListItem,
  ListIcon,
  Text,
  VStack,
  useColorModeValue,
  HStack,
  ScaleFade,
} from "@chakra-ui/react";
import { CheckCircleIcon, StarIcon } from "@chakra-ui/icons";

const FeatureList = () => {
  const bg = useColorModeValue("white", "gray.800");
  const boxBg = useColorModeValue("gray.50", "gray.700");
  const headingColor = useColorModeValue("teal.500", "teal.300");
  const iconColor = useColorModeValue("teal.400", "teal.200");
  const differentBoxBg = useColorModeValue("purple.50", "purple.900");
  const differentIconColor = useColorModeValue("purple.500", "purple.300");

  return (
    <Box
      maxW={{ base: "100%", lg: "50%" }}
      position={"absolute"}
      left={{ base: "5%", md: "17%", lg: "30%" }}
      p={{ base: 2, lg: 6 }}
      mt={{ base: "20%", lg: "2%" }}
      borderRadius="md"
      boxShadow="lg"
      bg={bg}
    >
      <Heading
        as="h2"
        size="xl"
        mb={{ base: 2, lg: 6 }}
        textAlign="center"
        color={headingColor}
      >
        AI-Powered Interview Tools
      </Heading>
      <VStack spacing={6} align="center" p="5%">
        <FeatureItem
          icon={CheckCircleIcon}
          title="Customized Mock Job Interviews"
          description="Generate personalized mock interviews tailored for your dream job. Practice with AI-driven questions and receive detailed feedback to sharpen your interview skills."
          boxBg={boxBg}
          iconColor={iconColor}
        />
        <FeatureItem
          icon={CheckCircleIcon}
          title="Employer-Created Custom Interviews"
          description="Employers can curate interviews using our vast pool of questions or add custom questions to assess candidates effectively. Share these with candidates for seamless screening."
          boxBg={boxBg}
          iconColor={iconColor}
        />
        <FeatureItem
          icon={CheckCircleIcon}
          title="AI-Driven Candidate Screening"
          description="Simplify candidate evaluation with AI-analyzed responses. Gain insights on communication skills, technical knowledge, and cultural fit in real-time."
          boxBg={boxBg}
          iconColor={iconColor}
        />
        <FeatureItem
          icon={StarIcon}
          title="More Features Coming Soon"
          description="Stay tuned for advanced tools like collaborative interview panels, detailed analytics, and industry-specific question banks."
          boxBg={differentBoxBg}
          iconColor={differentIconColor}
          isSpecial
        />
      </VStack>
    </Box>
  );
};

const FeatureItem = ({
  icon,
  title,
  description,
  boxBg,
  iconColor,
  isSpecial,
}) => (
  <ScaleFade initialScale={0.9} in>
    <Box
      bg={boxBg}
      p={4}
      borderRadius="md"
      boxShadow="md"
      width="100%"
      border={isSpecial ? "2px solid" : ""}
      borderColor={isSpecial ? iconColor : ""}
      transform={isSpecial ? "scale(1.05)" : ""}
      transition="transform 0.3s ease, border-color 0.3s ease"
      _hover={{
        transform: "scale(1.1)",
        borderColor: "transparent",
      }}
    >
      <List spacing={2}>
        <ListItem>
          <HStack>
            <ListIcon as={icon} color={iconColor} />
            <Text as="b" fontSize={isSpecial ? "lg" : "md"}>
              {title}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <Text>{description}</Text>
        </ListItem>
      </List>
    </Box>
  </ScaleFade>
);

export default FeatureList;
