import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PaymentPlans from "../home/PaymentPlans";
import { useDispatch, useSelector } from "react-redux";
import { hitBeApi } from "../../api/api";

const PaymentAction = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPaymentStarted, setIsPaymentStarted] = useState(false);
  const toast = useToast();
  const user = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  const handleRzpPayment = async (selectedPlan) => {
    if ((!user?.phoneNumber && !user?.email) || !user.name) {
      toast({
        title: "Error",
        description: "Please fill all required details.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (isPaymentStarted) {
      return;
    }

    try {
      setIsPaymentStarted(true);
      const orderData = await hitBeApi("order/razorpay", "POST", {
        amount: selectedPlan.price,
        currency: "INR",
        phoneNumber: user.phoneNumber,
        email: user.email,
        name: user.name,
        plan: selectedPlan?.title || "",
        userId: user?._id,
      });

      const options = {
        key: "rzp_live_n3sF2KwpuUE4Km", // Replace with your Razorpay key
        amount: orderData.data?.orderDetails?.amount,
        currency: orderData.data.orderDetails.currency,
        name: "TryInterview AI",
        description: selectedPlan?.plan_intro,
        order_id: orderData.data.razorpay.orderId,
        modal: {
          ondismiss: function () {
            setIsPaymentStarted(false);
          },
        },
        handler: async function (response) {
          try {
            const updatedUser = await hitBeApi("payment", "POST", {
              contact: user.phoneNumber,
              email: user.email,
              amount: selectedPlan.price,
              paymentPlan: selectedPlan?.title,
              orderId: orderData.data.razorpay.orderId,
              paymentId: response.razorpay_payment_id,
              userId: user?._id,
            });

            if (updatedUser) {
              dispatch({
                type: "SET_PROFILE",
                value: response.data,
              });
            }

            toast({
              title: "Payment Successful",
              description: `${"Your payment has been processed successfully!"}`,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            onClose();
            setIsPaymentStarted(false);
            refreshPage();
          } catch (error) {
            console.error("Error processing payment:", error);

            toast({
              title: "Payment Error",
              description:
                error.message ||
                "There was an error processing your payment. Please try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsPaymentStarted(false);
          }
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.phoneNumber,
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error processing order:", error);

      toast({
        title: "Order Error",
        description:
          error.message ||
          "There was an error processing your order. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsPaymentStarted(false);
    }
  };

  const handlePlanSelection = async (plan) => {
    await handleRzpPayment(plan);
    onClose(); // Close the modal after plan selection
  };

  return (
    <>
      <Button colorScheme="purple" onClick={onOpen}>
        Buy More AI Credits
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered maxHeight={"80%"}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "100%", lg: "60%" }}>
          {" "}
          {/* Set a max-width if needed */}
          <ModalCloseButton />
          <ModalBody p={6}>
            <Box>
              <PaymentPlans
                isHomePage={false}
                isPaymentStarted={isPaymentStarted}
                onSelectPlan={handlePlanSelection} // Pass function to handle plan selection
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PaymentAction;
