import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const CallToAction = () => {
  const bgColor = useColorModeValue("teal.500", "teal.300");
  const textColor = useColorModeValue("white", "gray.800");

  return (
    <Box
      py={20}
      px={8}
      bg={bgColor}
      color={textColor}
      textAlign="center"
      id="call-to-action"
    >
      <VStack spacing={8} maxW="3xl" mx="auto">
        <Heading as="h2" size="2xl" fontWeight="bold">
          Elevate Your Interview Game with AI
        </Heading>
        <Text fontSize="xl" maxW="2xl">
          Take your job preparation to the next level! Practice realistic mock 
          interviews with AI-powered avatars that simulate real-life scenarios, 
          or create tailored interviews from our extensive question pool to 
          share with candidates for effective screening.
        </Text>
        <Text fontSize="lg" maxW="2xl">
          Whether you're an aspiring job seeker or an employer, our tools offer 
          interactive AI agents, instant feedback, and advanced analytics to help 
          you prepare, evaluate, and succeed. Get started today!
        </Text>
        <VStack spacing={4}>
          <Button
            size="lg"
            bg="teal.500"
            border="2px solid"
            borderColor="white"
            _hover={{ bg: "teal.600", borderColor: "white" }}
            color="white"
          >
            <Link
              to="/login"
              style={{
                color: "inherit",
                textDecoration: "none",
                width: "100%",
              }}
            >
              Try AI-Powered Mock Interviews
            </Link>
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

export default CallToAction;
