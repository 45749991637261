import React from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Link,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";

// Example blog data for each route
const blogData = [
  {
    title: "Mock Interview Practice",
    image:
      "https://images.pexels.com/photos/7821936/pexels-photo-7821936.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Learn how to practice mock interviews to prepare for your next job opportunity.",
    link: "/mock-interview-practice",
  },
  {
    title: "Mock Interview Online",
    image:
      "https://images.pexels.com/photos/5668858/pexels-photo-5668858.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Discover the benefits of online mock interviews and how they can help boost your confidence.",
    link: "/mock-interview-online",
  },
  {
    title: "Mock Interview Questions",
    image:
      "https://images.pexels.com/photos/4344878/pexels-photo-4344878.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Find the most common mock interview questions and prepare for your upcoming interviews.",
    link: "/mock-interview-questions",
  },
  {
    title: "Mock Interview with AI",
    image:
      "https://images.pexels.com/photos/8879107/pexels-photo-8879107.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Get personalized feedback and practice with AI-powered mock interview sessions.",
    link: "/mock-interview-ai",
  },
  {
    title: "Interview Mock",
    image:
      "https://images.pexels.com/photos/4340139/pexels-photo-4340139.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "A comprehensive guide on mock interviews and their role in preparing for real interviews.",
    link: "/interview-mock",
  },
  {
    title: "Interview Preparation",
    image:
      "https://images.pexels.com/photos/5668869/pexels-photo-5668869.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Get the best strategies for preparing for interviews effectively and efficiently.",
    link: "/interview-preparation",
  },
  {
    title: "Interview Mock",
    image:
      "https://images.pexels.com/photos/4226118/pexels-photo-4226118.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Practice with mock tests designed to simulate real interview conditions.",
    link: "/interview-mock-test",
  },
  {
    title: "Interview Warmup",
    image:
      "https://images.pexels.com/photos/5336958/pexels-photo-5336958.jpeg?auto=compress&cs=tinysrgb&w=800",
    description:
      "How to warm up before an interview to boost your performance and confidence.",
    link: "/interview-warmup",
  },
  {
    title: "Interview Tips",
    image:
      "https://images.pexels.com/photos/5273664/pexels-photo-5273664.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Essential tips and advice to help you succeed in your job interviews.",
    link: "/interview-tips",
  },
  {
    title: "Interview Prep",
    image:
      "https://images.pexels.com/photos/7841797/pexels-photo-7841797.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Complete interview preparation guide to help you excel in your next job interview.",
    link: "/interview-prep",
  },
  {
    title: "Best Interview Preparation ",
    image:
      "https://images.pexels.com/photos/7176296/pexels-photo-7176296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Find the best resources and techniques for effective interview preparation.",
    link: "/best-interview-preparation",
  },
  {
    title: "Best Interview Introduction",
    image:
      "https://images.pexels.com/photos/4344860/pexels-photo-4344860.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "An introduction to the best practices and strategies for interview preparation.",
    link: "/best-interview-introduction",
  },
  {
    title: "Best Interview Questions and Answers",
    image:
      "https://images.pexels.com/photos/8111887/pexels-photo-8111887.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "The most commonly asked interview questions and how to answer them effectively.",
    link: "/best-interview-questions-and-answers",
  },
  {
    title: "Best Interview Preparation Sites",
    image:
      "https://images.pexels.com/photos/6953672/pexels-photo-6953672.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Explore the best websites to enhance your interview preparation.",
    link: "/best-interview-preparation-sites",
  },
  {
    title: "Best Interview Preparation Course",
    image:
      "https://images.pexels.com/photos/5921981/pexels-photo-5921981.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Discover the best interview preparation courses and how they can help you succeed.",
    link: "/best-interview-preparation-course",
  },
];

const BlogList = () => {
  // Use Chakra UI's useBreakpointValue hook to dynamically adjust the number of columns
  const columns = useBreakpointValue({ base: 1, lg: 3 });

  return (
    <SimpleGrid
      columns={columns}
      spacing={8}
      padding="4"
      maxW="1200px"
      margin="auto"
      pt={12}
    >
      {blogData.map((blog, index) => (
        <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Image src={blog.image} alt={blog.title} width="100%" height="auto" />
          <Box p="6">
            <Heading as="h3" size="lg" mb="4">
              <Link href={blog.link} color="teal.500" isExternal>
                {blog.title}
              </Link>
            </Heading>
            <Text fontSize="md" color="gray.600">
              {blog.description}
            </Text>
          </Box>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default BlogList;
