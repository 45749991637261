import React from 'react';
import { Box, Heading, Text, VStack, Link, List, ListItem, useColorMode } from '@chakra-ui/react';

const MockInterviewQuestions = () => {
  const { colorMode } = useColorMode();
  const linkColor = colorMode === 'light' ? 'blue.600' : 'blue.300';

  return (
    <Box p={8}>
      <VStack spacing={6} align="start">
        <Heading as="h1" size="2xl" mb={4}>
          Mock Interview Questions: Your Guide to Acing Any Interview
        </Heading>

        <Text fontSize="lg">
          Preparing for an interview can be nerve-wracking, especially when you don’t know what kind of questions to expect. At
          {" "}<Link href="https://tryinterviewai.com" color={linkColor} isExternal>
            TryInterviewAI.com
          </Link>, we make it easy for you to practice mock interviews tailored to your specific needs.
        </Text>

        <Heading as="h2" size="xl" mt={6}>
          Why Practice Mock Interview Questions?
        </Heading>
        <Text fontSize="md">
          Practicing mock interview questions helps you build confidence, improve your communication skills, and identify areas where you need improvement. Using a platform like TryInterviewAI.com allows you to simulate real-world interviews, receive instant feedback, and track your progress over time.
        </Text>

        <Heading as="h2" size="xl" mt={6}>
          Common Mock Interview Questions
        </Heading>
        <Text fontSize="md">
          Below is a list of frequently asked interview questions that you can practice using our AI-driven platform:
        </Text>

        <Heading as="h3" size="lg" mt={4}>
          General Questions
        </Heading>
        <List spacing={3} pl={4}>
          <ListItem>Tell me about yourself.</ListItem>
          <ListItem>What are your greatest strengths and weaknesses?</ListItem>
          <ListItem>Why do you want to work for this company?</ListItem>
          <ListItem>Where do you see yourself in five years?</ListItem>
          <ListItem>How do you handle stress or pressure?</ListItem>
        </List>

        <Heading as="h3" size="lg" mt={4}>
          Behavioral Questions
        </Heading>
        <Text fontSize="md">
          Behavioral questions are designed to understand how you’ve handled situations in the past. These are best answered using the STAR method (Situation, Task, Action, Result):
        </Text>
        <List spacing={3} pl={4}>
          <ListItem>Give an example of a challenge you faced at work and how you overcame it.</ListItem>
          <ListItem>Describe a time when you led a team to success.</ListItem>
          <ListItem>Tell me about a time you had to make a difficult decision at work.</ListItem>
          <ListItem>How do you prioritize tasks when faced with tight deadlines?</ListItem>
          <ListItem>Describe a situation where you had to resolve a conflict within your team.</ListItem>
        </List>

        <Heading as="h3" size="lg" mt={4}>
          Technical Questions
        </Heading>
        <Text fontSize="md">
          For technical roles, you’ll often encounter specific questions related to your domain of expertise. Here are some examples:
        </Text>
        <List spacing={3} pl={4}>
          <ListItem>Explain the concept of Object-Oriented Programming.</ListItem>
          <ListItem>What is the difference between REST and GraphQL?</ListItem>
          <ListItem>How would you optimize a database query?</ListItem>
          <ListItem>Walk me through a project you’ve worked on and the challenges you faced.</ListItem>
          <ListItem>What debugging techniques do you use to solve code issues?</ListItem>
        </List>

        <Heading as="h2" size="xl" mt={6}>
          How TryInterviewAI.com Can Help
        </Heading>
        <Text fontSize="md">
          At TryInterviewAI.com, we offer a unique and personalized interview preparation experience. Here’s how we can help:
        </Text>
        <List spacing={3} pl={4}>
          <ListItem>
            <strong>Customized Mock Interviews:</strong> Our AI adapts to your career level, field, and specific goals.
          </ListItem>
          <ListItem>
            <strong>Instant Feedback:</strong> Receive constructive feedback on your answers to improve with every practice session.
          </ListItem>
          <ListItem>
            <strong>Real-Time Simulations:</strong> Simulate real interview scenarios with dynamic and evolving questions.
          </ListItem>
          <ListItem>
            <strong>Track Your Progress:</strong> Monitor your improvements with detailed analytics and performance reports.
          </ListItem>
        </List>

        <Heading as="h2" size="xl" mt={6}>
          Tips for Answering Mock Interview Questions
        </Heading>
        <Text fontSize="md">
          While practicing, keep the following tips in mind to maximize your performance:
        </Text>
        <List spacing={3} pl={4}>
          <ListItem>
            <strong>Be Concise:</strong> Avoid long-winded answers. Keep your responses focused and to the point.
          </ListItem>
          <ListItem>
            <strong>Use Examples:</strong> Wherever possible, back up your answers with real-life examples.
          </ListItem>
          <ListItem>
            <strong>Stay Positive:</strong> Even when discussing challenges or failures, emphasize what you learned from the experience.
          </ListItem>
          <ListItem>
            <strong>Practice Regularly:</strong> The more you practice, the more confident you’ll become.
          </ListItem>
          <ListItem>
            <strong>Seek Feedback:</strong> Use TryInterviewAI.com to identify areas where you can improve.
          </ListItem>
        </List>

        <Heading as="h2" size="xl" mt={6}>
          Final Thoughts
        </Heading>
        <Text fontSize="md">
          Practicing mock interview questions is one of the best ways to prepare for any job interview. With platforms like
          {" "}<Link href="https://tryinterviewai.com" color={linkColor} isExternal>
            TryInterviewAI.com
          </Link>, you can gain the confidence and skills needed to make a lasting impression on interviewers. Don’t leave your future to chance—start practicing today!
        </Text>
      </VStack>
    </Box>
  );
};

export default MockInterviewQuestions;
