import React, { useEffect, useRef } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Avatar,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";

// Updated testimonials data with relevant feedback
const testimonials = [
  {
    name: "Abhishek Shringi",
    image:
      "https://media.licdn.com/dms/image/C5603AQGze3u3GELVBA/profile-displayphoto-shrink_800_800/0/1600970582436?e=1729123200&v=beta&t=NggNk03sLcnhQ8yaYTcQgoQkrp9HatLx0ujcahCSx-c",
    text: "The AI-driven mock interviews were so realistic that I felt well-prepared for my actual job interview!",
  },
  {
    name: "Diptanshu Ranjan",
    image:
      "https://media.licdn.com/dms/image/v2/C5103AQGqBQWs_GdUwA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1555244639505?e=1729123200&v=beta&t=MBHnnMtei7LutJqcFkHRDDid5oNu270BWjbaeIGImac",
    text: "Creating custom interviews for my candidates was seamless and saved so much time! Highly recommend TryInterview AI!",
  },
  {
    name: "Rishav Rajak",
    image:
      "https://media.licdn.com/dms/image/C4D03AQHdJxnyVgM76Q/profile-displayphoto-shrink_400_400/0/1600349522568?e=1729123200&v=beta&t=WEt71wZwf31SHdSYZsgmoJAut8iexMz_QxGh7Cj_s9M",
    text: "The analytics provided detailed insights about candidate performance, making screening so much more efficient!",
  },
  {
    name: "Akshdeep Singh",
    image:
      "https://media.licdn.com/dms/image/C4D03AQELdt8Whp-VPw/profile-displayphoto-shrink_400_400/0/1597566460226?e=1729123200&v=beta&t=ufK1YSBiihCEzhV0742VBBVcGKV1JjmyzB0gHHrDLn8",
    text: "The ability to upload custom questions made it easy to tailor interviews to my company’s needs. Amazing platform!",
  },
  {
    name: "Kashi Roy",
    image:
      "https://media.licdn.com/dms/image/v2/D5603AQEtiFJmnzN5YQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1719752778068?e=1729123200&v=beta&t=cyv0VCWgILq64c8Ya6MnXlalWuQumrtus50m0U_3XZk",
    text: "I loved how easy it was to practice mock interviews and get immediate, actionable feedback from AI!",
  },
  {
    name: "Ashwini Gautam",
    image:
      "https://media.licdn.com/dms/image/v2/D5603AQEmzvuux1vfqA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1718236034784?e=1729123200&v=beta&t=-6WqODnyhvFacnJNoEsRB_zacVef6XOGdcZ0vWnzOcc",
    text: "The platform made interview preparation engaging and effective. It’s a must-have for job seekers!",
  },
  {
    name: "Franklin Gari",
    image:
      "https://media.licdn.com/dms/image/v2/C5603AQE-jD7TKjYPOQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1624092537259?e=1729123200&v=beta&t=jofNn7ubIUWPWM1gcrRMrNYRUrK-BxV2IpUxzbhcRhQ",
    text: "The AI suggestions for improving my answers were spot on. Great learning experience!",
  },
  {
    name: "Ashar Mirza",
    image:
      "https://media.licdn.com/dms/image/v2/D5603AQFs07zOc095Pw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1685358122846?e=1729123200&v=beta&t=4OZUZFkNVRTKe0tCJLDisuHkTCe_kv6Fi-I1yehLS-M",
    text: "The platform’s focus on personalized questions made preparation so much more relevant and impactful!",
  },
  {
    name: "Aman Agrawal",
    image:
      "https://media.licdn.com/dms/image/v2/D4D03AQEMQihkMId5Bg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1682344229169?e=1729123200&v=beta&t=ZwkHLE9m6x_scInfBU0Jc0UDbdnstaqoUzJiXfjcN9A",
    text: "Practicing with timed interviews helped me build confidence for real-world scenarios. Outstanding feature!",
  },
  {
    name: "Isha Chaudhary",
    image:
      "https://media.licdn.com/dms/image/C4E03AQGF0HwzEw6TXg/profile-displayphoto-shrink_400_400/0/1626865062660?e=1729123200&v=beta&t=jz9qhISN42OcL4PJU1eAiG6my3S5-upmRaecQSkz3xE",
    text: "A comprehensive platform with excellent support for both candidates and employers. Highly versatile!",
  },
];

const Testimonials = () => {
  const sliderRef = useRef(null);
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const headingColor = useColorModeValue("gray.700", "white");
  const cardBgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    const slider = sliderRef.current;
    let scrollAmount = 0;
    const scrollStep = 2; // Adjust scroll speed
    const scrollInterval = 30; // Adjust scroll interval

    const scrollTestimonials = () => {
      if (slider.scrollLeft >= slider.scrollWidth - slider.clientWidth) {
        scrollAmount = 0;
      } else {
        scrollAmount += scrollStep;
      }
      slider.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    };

    const scrollIntervalId = setInterval(scrollTestimonials, scrollInterval);

    return () => clearInterval(scrollIntervalId);
  }, []);

  return (
    <Box py={20} px={8} bg={bgColor} id="testimonials" maxW="100%">
      <VStack spacing={8} align="center">
        <Heading as="h2" size="xl" color={headingColor}>
          Testimonials
        </Heading>
        <Box overflowX="hidden" ref={sliderRef} w="full">
          <HStack spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Box
                key={index}
                w={{ base: "100%", sm: "50%", md: "33.33%" }}
                p={5}
                flex="0 0 auto"
              >
                <VStack
                  spacing={4}
                  bg={cardBgColor}
                  p={6}
                  boxShadow="lg"
                  borderRadius="md"
                  textAlign="center"
                >
                  <Avatar
                    src={testimonial.image}
                    name={testimonial.name}
                    size="xl"
                  />
                  <Text fontWeight="bold" fontSize="lg" color={textColor}>
                    {testimonial.name}
                  </Text>
                  <Text fontSize="md" color={textColor}>
                    {testimonial.text}
                  </Text>
                </VStack>
              </Box>
            ))}
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default Testimonials;
